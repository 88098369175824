import { defineMessages } from 'react-intl';

export const addAltText = ({ schema, intl, formData }) => {
  const fieldset = 'default';

  const beforeField = 'linkTitle';

  let fieldsetIndex = schema.fieldsets.findIndex((x) => x.id === fieldset);

  let position = schema.fieldsets[fieldsetIndex]?.fields.indexOf(beforeField);

  // eslint-disable-next-line no-unused-expressions
  schema.fieldsets[fieldsetIndex]?.fields?.splice(position, 0, 'alt_text');
  schema.properties['alt_text'] = {
    title: intl.formatMessage(messages.altText),
    description: intl.formatMessage(messages.altTextDescription),
  };
  position++;

  return schema;
};

const messages = defineMessages({
  altText: {
    id: 'altText',
    defaultMessage: "Testo alternativo dell'immagine",
  },
  altTextDescription: {
    id: 'altTextDescription',
    defaultMessage: "Aggiungi un testo alternativo per l'immagine",
  },
});
