/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Logo, UniversalLink } from '@plone/volto/components';
import { SocialLinks } from 'volto-social-settings';

import { useGoogleAnalytics } from 'volto-google-analytics';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
// import Button from '@package/components/Button/Button';

import { getItemsByPath } from 'volto-dropdownmenu/utils';
import { displayBanner } from 'volto-gdpr-privacy';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
  cookieSettings: {
    id: 'Cookie settings',
    defaultMessage: 'Cookie settings',
  },
  customersArea: {
    id: 'Customers Area',
    defaultMessage: 'Area clienti',
  },
  privacyAndCookies: {
    id: 'Privacy and Cookies',
    defaultMessage: 'Privacy e Cookie',
  },
  legal: {
    id: 'Legal',
    defaultMessage: 'Legal',
  },
  contacts: {
    id: 'Contacts',
    defaultMessage: 'Contacts',
  },
  footerInfos: {
    id: 'Footer infos',
    defaultMessage:
      'Sede legale: Roma via Teulada 66 cap 00195 | Capitale sociale euro 70.176.000,00 interamente versato | Ufficio del Registro delle Imprese di Roma Codice Fiscale 05820021003 | Società soggetta ad attività di direzione e coordinamento di RAI - Radiotelevisione italiana Spa Rai Way S.p.A. © 2023',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = () => {
  useGoogleAnalytics();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const dropdownMenuNavItems = useSelector(
    (state) => state.dropdownMenuNavItems?.result,
  );

  const menu = getItemsByPath(dropdownMenuNavItems, pathname).filter(
    (m) => !m.additionalClasses || m.additionalClasses?.indexOf('home') < 0,
  );

  return (
    <div id="footer">
      <Container>
        <div id="footer-main">
          {menu.map((m, i) => {
            const link =
              m.mode === 'dropdown' ? m.showMoreLink?.[0] : m.linkUrl?.[0];
            return (
              <div className="footer-column" key={i}>
                <div className="h4">
                  {link ? (
                    <UniversalLink item={link}>{m.title}</UniversalLink>
                  ) : (
                    m.title
                  )}
                </div>
                {m.navigationRoot?.length > 0 && (
                  <ul>
                    {m.navigationRoot.map((nr, ii) => (
                      <li key={i + '-' + ii}>
                        <UniversalLink item={nr}>{nr.title}</UniversalLink>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}

          <div className="footer-column">
            {/* <Button
              color="outline-blue"
              size="tiny"
              as={UniversalLink}
              href="/azienda"
            >
              {intl.formatMessage(messages.customersArea)}{' '}
              <FontAwesomeIcon icon={faArrowRight} />
            </Button> */}
            <SocialLinks />
            <ul>
              <li>
                <button
                  className="footer-gdpr-privacy-show-banner"
                  onClick={(e) => {
                    dispatch(displayBanner(true, true));
                  }}
                  title={intl.formatMessage(messages.cookieSettings)}
                >
                  {intl.formatMessage(messages.cookieSettings)}
                </button>
              </li>
              <li>
                <UniversalLink
                  href={
                    intl.locale === 'it'
                      ? '/it/privacy-e-cookies'
                      : '/en/privacy-and-cookies'
                  }
                >
                  {intl.formatMessage(messages.privacyAndCookies)}
                </UniversalLink>
              </li>
              {/* <li>
                <UniversalLink href="/it/legal">
                  {intl.formatMessage(messages.legal)}
                </UniversalLink>
              </li> */}
              <li>
                <UniversalLink
                  href={intl.locale === 'it' ? '/it/contatti' : '/en/contacts'}
                >
                  {intl.formatMessage(messages.contacts)}
                </UniversalLink>
              </li>
            </ul>
          </div>
        </div>
        <div id="footer-small">
          <div className="logo">
            <Logo />
          </div>
          <div className="ragsociale">Rai Way S.p.A.</div>
          <div className="address">
            {intl.formatMessage(messages.footerInfos)}
          </div>
        </div>
      </Container>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default Footer;
