import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  CheckboxWidget,
  ObjectBrowserWidget,
  TextWidget,
  SelectWidget,
} from '@plone/volto/components';
import { TextBlockStyleSidebar } from '@package/components';

const messages = defineMessages({
  has_cta: {
    id: 'has_cta',
    defaultMessage: 'Mostra la CTA',
  },
  has_cta_description: {
    id: 'has_cta_description',
    defaultMessage:
      'Scegli se mostrare o meno la CTA nel blocco, assente di default.',
  },
  cta_title: {
    id: 'cta_title',
    defaultMessage: 'Testo della CTA',
  },
  cta_title_default: {
    id: 'cta_title_default',
    defaultMessage: 'Vai al contenuto',
  },
  cta_title_description: {
    id: 'cta_title_description',
    defaultMessage: 'Testo da mostrare per la CTA del blocco.',
  },
  link_to: {
    id: 'link_to',
    defaultMessage: 'Link CTA',
  },
  link_to_description: {
    id: 'link_to_description',
    defaultMessage:
      'Inserisci un collegamento per la CTA del blocco. Puoi digitare un URL esterno o selezionare un contenuto.',
  },
  destra: {
    id: 'destra',
    defaultMessage: 'Immagine a destra',
  },
  destra_description: {
    id: 'destra_description',
    defaultMessage:
      "Allinea l'immagine a destra, di default questa viene allineata a sinistra.",
  },
  img_column_width: {
    id: 'img_column_width',
    defaultMessage: "Larghezza dell'immagine",
  },
  floating_text: {
    id: 'floating_text',
    defaultMessage: "Testo flottante attorno all' immagine",
  },
  altText: {
    id: 'altText',
    defaultMessage: "Testo alternativo dell'immagine",
  },
  altTextDescription: {
    id: 'altTextDescription',
    defaultMessage: "Aggiungi un testo alternativo per l'immagine",
  },
});

const Testo7Sidebar = (props) => {
  const intl = useIntl();
  const {
    has_cta,
    cta_title,
    alt_text,
    destra,
    link_to,
    img_column_width,
    floating_text,
    onChange,
  } = props;
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="testo7" defaultMessage="Testo 7" />
        </h2>
      </header>
      <TextBlockStyleSidebar
        {...props}
        backgroundColorOptions={[
          { name: 'white', label: 'Bianco' },
          { name: 'light-grey', label: 'Grigio' },
          { name: 'blue', label: 'Blu' },
        ]}
      />

      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="floating_text"
          title={intl.formatMessage(messages.floating_text)}
          value={floating_text}
          onChange={onChange}
        />
      </Segment>

      <Segment className="form sidebar-listing-data">
        <TextWidget
          id="alt_text"
          title={intl.formatMessage(messages.altText)}
          description={intl.formatMessage(messages.altTextDescription)}
          value={alt_text ? alt_text : ''}
          onChange={onChange}
          placeholder={intl.formatMessage(messages.altText)}
        />
      </Segment>

      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="destra"
          title={intl.formatMessage(messages.destra)}
          value={destra}
          onChange={onChange}
        />
        <SelectWidget
          id="img_column_width"
          title={intl.formatMessage(messages.img_column_width)}
          choices={[
            ['1', '8%'],
            ['2', '16%'],
            ['3', '25%'],
            ['4', '33%'],
            ['5', '41%'],
            ['6', '50%'],
            ['7', '58%'],
            ['8', '66%'],
            ['9', '75%'],
            ['10', '83%'],
            ['11', '91%'],
          ]}
          value={img_column_width ?? 6}
          onChange={onChange}
        />
      </Segment>
      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="has_cta"
          title={intl.formatMessage(messages.has_cta)}
          value={has_cta}
          onChange={onChange}
        />
        {has_cta && (
          <>
            <TextWidget
              id="cta_title"
              title={intl.formatMessage(messages.cta_title)}
              description={intl.formatMessage(messages.cta_title_description)}
              value={cta_title ?? ''}
              onChange={onChange}
              placeholder={intl.formatMessage(messages.cta_title_default)}
            />
            <ObjectBrowserWidget
              id="link_to"
              title={intl.formatMessage(messages.link_to)}
              description={intl.formatMessage(messages.link_to_description)}
              mode="link"
              required={has_cta}
              value={link_to}
              onChange={onChange}
              allowExternals={true}
            />
          </>
        )}
      </Segment>
    </Segment.Group>
  );
};

Testo7Sidebar.propTypes = {
  has_cta: PropTypes.bool,
  destra: PropTypes.bool,
  link_to: PropTypes.any,
  link_to_external: PropTypes.string,
  cta_title: PropTypes.string,
  onChange: PropTypes.func,
  intl: PropTypes.any,
};
export default Testo7Sidebar;
