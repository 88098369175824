import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ObjectListWidget } from '@plone/volto/components';
import { TextBlockStyleSidebar } from '@package/components';
const messages = defineMessages({
  has_cta: {
    id: 'has_cta',
    defaultMessage: 'Mostra la CTA',
  },
  has_cta_description: {
    id: 'has_cta_description',
    defaultMessage:
      'Scegli se mostrare o meno la CTA nel blocco, assente di default.',
  },
  cta_title: {
    id: 'cta_title',
    defaultMessage: 'Testo della CTA',
  },
  cta_title_default: {
    id: 'cta_title_default',
    defaultMessage: 'Vai al contenuto',
  },
  cta_title_description: {
    id: 'cta_title_description',
    defaultMessage: 'Testo da mostrare per la CTA del blocco.',
  },
  link_to: {
    id: 'link_to',
    defaultMessage: 'Link CTA interno',
  },
  link_to_description: {
    id: 'link_to_description',
    defaultMessage:
      'Inserisci un collegamento ad un contenuto interno per la CTA del blocco.',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Immagine',
  },
  link_image: {
    id: 'link_image',
    defaultMessage: 'Link immagine',
  },
  altText: {
    id: 'altText',
    defaultMessage: "Testo alternativo dell'immagine",
  },
  altTextDescription: {
    id: 'altTextDescription',
    defaultMessage: "Aggiungi un testo alternativo per l'immagine",
  },
});

const Testo5Sidebar = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Testo 5" defaultMessage="Testo 5" />
        </h2>
      </header>
      <TextBlockStyleSidebar
        {...props.data}
        onChange={(fieldName, value) => {
          onChangeBlock(block, {
            ...data,
            [fieldName]: value,
          });
        }}
      />

      <Segment className="form sidebar-listing-data">
        <ObjectListWidget
          {...props}
          id="images"
          value={data?.images ?? []}
          onChange={(id, value) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const imageSchema = {
              title: 'Image',
              addMessage: "Aggiungi un'immagine",
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: ['image', 'alt_text'],
                },
              ],
              properties: {
                image: {
                  title: intl.formatMessage(messages.link_image),
                  description:
                    "L'immagine deve essere un PNG di almeno 600x400 px",
                  widget: 'image_upload_widget',
                  openObjectBrowser: props.openObjectBrowser,
                },
                alt_text: {
                  title: intl.formatMessage(messages.altText),
                  description: intl.formatMessage(messages.altTextDescription),
                },
              },
              required: [],
            };

            return imageSchema;
          }}
        />
        {/* <CheckboxWidget
          id="has_cta"
          title={intl.formatMessage(messages.has_cta)}
          value={data.has_cta}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        {data.has_cta && (
          <>
            <TextWidget
              id="cta_title"
              title={intl.formatMessage(messages.cta_title)}
              description={intl.formatMessage(messages.cta_title_description)}
              value={data.cta_title ?? ''}
              onChange={(name, value) => {
                onChangeBlock(block, { ...data, [name]: value });
              }}
              placeholder={intl.formatMessage(messages.cta_title_default)}
            />
            <ObjectBrowserWidget
              id="link_to"
              title={intl.formatMessage(messages.link_to)}
              description={intl.formatMessage(messages.link_to_description)}
              mode="link"
              required={data.has_cta}
              value={data.link_to}
              onChange={(name, value) => {
                onChangeBlock(block, { ...data, [name]: value });
              }}
              allowExternals={true}
            />
          </>
        )} */}
      </Segment>
    </Segment.Group>
  );
};

Testo5Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};
export default Testo5Sidebar;
