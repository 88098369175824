import Button from '@package/components/Button/Button';
import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  scrollToTop: {
    id: 'Scroll to top',
    defaultMessage: 'Torna su',
  },
});

const ScrollToTop = () => {
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  return isVisible ? (
    <Button
      onClick={(_e) =>
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
      className="scrollToTop"
      arrow
      title={intl.formatMessage(messages.scrollToTop)}
      aria-label={intl.formatMessage(messages.scrollToTop)}
    />
  ) : null;
};

export default ScrollToTop;
