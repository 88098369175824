import { useIntl, defineMessages } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import ErrorImage from '@package/components/ErrorView/bassotto.png';
import CustomButton from '@package/components/Button/Button';

const ErrorView = (props) => {
  const pathname = props.pathname;
  const locale = pathname.startsWith('/en/') ? 'en' : 'it';
  const messages = locale === 'en' ? en_messages : it_messages;
  const intl = useIntl();
  return (
    <div className="ui container error-page">
      <div className="error-page-body">
        <div className="error-page-image">
          <img src={ErrorImage} alt="error" width="1060px" height="auto" />
        </div>
        <div className="error-page-content">
          <div className="error-page-title">
            <h2>{intl.formatMessage(messages.error_page_title)}</h2>
          </div>
          <div className="error-page-text">
            <p>{intl.formatMessage(messages.error_page_text)}</p>
            <p>{intl.formatMessage(messages.error_page_cta_intro)}</p>
          </div>
          <div className="error-page-cta">
            <CustomButton as={UniversalLink} href="/" size="big" arrow={true}>
              {intl.formatMessage(messages.error_page_cta)}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorView;

const it_messages = defineMessages({
  error_page_title: {
    id: 'error_page_title',
    defaultMessage: 'OPS! La pagina che stai cercando non esiste',
  },
  error_page_text: {
    id: 'error_page_text',
    defaultMessage:
      'Con tutta la cura che mettiamo nel fornire pagine e contenuti aggiornati, rimaniamo umani e può capitare anche a noi di sbagliare qualche link!',
  },
  error_page_cta_intro: {
    id: 'error_page_cta_intro',
    defaultMessage:
      'Ma non ti scoraggiare: clicca sul menu in alto o sul pulsante qui sotto per tornare al sito.',
  },
  error_page_cta: {
    id: 'error_page_cta',
    defaultMessage: 'Vai alla home',
  },
});

const en_messages = defineMessages({
  error_page_title: {
    id: 'en_error_page_title',
    defaultMessage: "Ooops! The page you're looking for does not exist.",
  },
  error_page_text: {
    id: 'en_error_page_text',
    defaultMessage:
      "Even though we put a lot of care into providing up-to-date pages and content, we're just humans and we might still get some links wrong!",
  },
  error_page_cta_intro: {
    id: 'en_error_page_cta_intro',
    defaultMessage:
      "But don't be discouraged: click on the menu above or the button below to return to the site.",
  },
  error_page_cta: {
    id: 'en_error_page_cta',
    defaultMessage: 'Go to homepage',
  },
});
