import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import {
  FormattedMessage,
  injectIntl,
  useIntl,
  defineMessages,
} from 'react-intl';
import { ColorListWidget } from '@package/components/Widgets';
import { CheckboxWidget } from '@plone/volto/components';
import {
  //CheckboxWidget,
  ObjectListWidget,
  TextWidget,
  ObjectBrowserWidget,
  SelectWidget,
} from '@plone/volto/components';

const Sidebar = (props) => {
  const intl = useIntl();

  const { data, onChangeBlock, block } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="Image columns"
            defaultMessage="Immagini affiancate"
          />
          :
        </h2>
      </header>
      <Segment className="form">
        <SelectWidget
          id="n_columns"
          title="Numero di colonne"
          description="Se il numero di colonne create è inferiore al numero selezionato, si adatteranno alla larghezza disponibile"
          choices={[
            ['3', '3'],
            ['4', '4'],
            ['5', '5'],
            ['6', '6'],
          ]}
          value={data.n_columns}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
      </Segment>
      <Segment className="form">
        <ColorListWidget
          id="bg_color"
          title="Colore di sfondo"
          colors={[
            { name: 'outline-white', label: 'Trasparente' },
            { name: 'light-grey', label: 'Grigio chiaro' },
            { name: 'blue', label: 'Blu' },
          ]}
          value={data.bg_color ?? 'outline-white'}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <CheckboxWidget
          id="fullWidth"
          title="Mostra lo sfondo a tutta larghezza"
          value={data.fullWidth ?? false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <CheckboxWidget
          id="noflexGrow"
          title="Non adattare le colonne allo spazio disponibile"
          value={data.noflexGrow ?? false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
      </Segment>
      <Segment className="form">
        <ObjectListWidget
          {...props}
          id="columns"
          value={data?.columns ?? []}
          onChange={(id, value) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const columnsSchema = {
              title: 'Colonna',
              addMessage: 'Aggiungi una colonna',
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: ['image', 'href', 'alt_text'],
                },
              ],
              properties: {
                image: {
                  title: 'Immagine',
                  description:
                    "L'immagine verrà ridimensionata e adattata alla larghezza della colonna se più grande dello spazio a dispozione. Diversamente, manterrà le sue proporizioni.",
                  widget: 'image_upload_widget',
                  openObjectBrowser: props.openObjectBrowser,
                },
                href: {
                  title: 'Link',
                  widget: 'object_browser',
                  allowExternals: true,
                  mode: 'link',
                },
                alt_text: {
                  title: intl.formatMessage(messages.altText),
                  description: intl.formatMessage(messages.altTextDescription),
                },
              },

              required: [],
            };

            return columnsSchema;
          }}
        />

        <ObjectBrowserWidget
          id="href"
          title="Link ad altro"
          mode="link"
          allowExternals={true}
          value={data.href}
          widgetOptions={{
            pattern_options: { maximumSelectionSize: 1 },
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              href: value,
            });
          }}
        />
        <TextWidget
          id="href_title"
          title="Titolo per il link ad altro"
          value={data.href_title ?? ''}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);

const messages = defineMessages({
  altText: {
    id: 'altText',
    defaultMessage: "Testo alternativo dell'immagine",
  },
  altTextDescription: {
    id: 'altTextDescription',
    defaultMessage: "Aggiungi un testo alternativo per l'immagine",
  },
});
