import { toPublicURL, flattenToAppURL } from '@plone/volto/helpers';
const IT_ORG_CT = [
  {
    contactType: 'Info Commerciali',
    email: 'commerciale@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Investor Relations',
    email: 'investor.relations@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Info Amministrative',
    email: 'raiway.info.amministrazione@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Info Legali',
    email: 'raiway@postacertificata.rai.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Info Media',
    email: 'comunicazione.esterna@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
];
const EN_ORG_CT = [
  {
    contactType: 'Sales Info',
    email: 'commerciale@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Investor Relations',
    email: 'investor.relations@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Accounting Info',
    email: 'raiway.info.amministrazione@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Legal Info',
    email: 'raiway@postacertificata.rai.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
  {
    contactType: 'Media Info',
    email: 'comunicazione.esterna@raiway.it',
    areaServed: 'Worldwide',
    availableLanguage: ['Italian', 'English'],
  },
];

const ORGANIZATION = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Rai Way S.p.A.',
  logo: toPublicURL('/logo.png'),
  url: toPublicURL(''),
  telephone: '+39 0687165750',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Via Teulada 66',
    addressLocality: 'Roma',
    addressRegion: 'RM',
    addressCountry: 'IT',
    postalCode: '00195',
  },
  vatID: 'IT05820021003',

  sameAs: [
    'https://www.linkedin.com/company/raiwayspa/',
    'https://twitter.com/Raiway',
    'https://www.youtube.com/channel/UCxh1zMplcDDq9_PWhohS6-g',
    'https://en.wikipedia.org/wiki/Rai_Way',
    'https://it.wikipedia.org/wiki/Rai_Way',
  ],
};

const NEWSARTICLE = {
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  author: {
    '@type': 'Organization',
    name: 'Rai Way S.p.A.',
    logo: toPublicURL('/logo.png'),
    url: toPublicURL(''),
  },
};

const WEBPAGE = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
};
const SERVICE = {
  '@context': 'https://schema.org',
  '@type': 'Service',

  provider: {
    '@type': 'Organization',
    name: 'Rai Way S.p.A.',
    logo: toPublicURL('/logo.png'),
    url: toPublicURL(''),
  },
  //   areaServed: {
  //     '@type': 'Country',
  //     name: 'Italia',
  //   },
  offers: {
    '@type': 'Offer',
    priceSpecification: {
      '@type': 'UnitPriceSpecification',
      priceCurrency: 'EUR',
    },
    availability: 'https://schema.org/InStock',
  },
};

const isDocument = (content) => content?.['@type'] === 'Document';
const isLRF = (content) => content?.['@type'] === 'LRF';
const isNewsItem = (content) => content?.['@type'] === 'News Item';
const rootLangNormalizer = (content, relativePath = '') => {
  if (typeof content.language === 'string') return content.language;
  else if (typeof content.language === 'object' && content.language.token)
    return content.language.token;
};
const orgSchema = (content) => {
  return {
    ...ORGANIZATION,
    description: content.seo_description || content.description || '',
    contactPoint: rootLangNormalizer(content) === 'it' ? IT_ORG_CT : EN_ORG_CT,
  };
};
const wpSchema = (content) => {
  return {
    ...WEBPAGE,
    url: toPublicURL(flattenToAppURL(content['@id'])),
    name: content.seo_title ? content.seo_title : content.title,
    description: content.seo_description || content.description || '',
  };
};
const naSchema = (content) => {
  const imageScales = ['huge', 'great', 'larger'];
  const selectedImages = imageScales
    .map((scale) => content.image?.scales?.[scale]?.download)
    .filter(Boolean); // Filtra gli URL validi
  return {
    ...NEWSARTICLE,
    image: selectedImages,
    headline: content.seo_title ? content.seo_title : content.title,
    description: content.seo_description || content.description || '',
    datePublished: content.effective,
    dateModified: content.modified,
    author: {
      ...NEWSARTICLE.author,
      url: toPublicURL(''),
    },
  };
};

const serviceSchema = (content) => {
  const parent = content.parent;
  const baseSchema = {
    ...SERVICE,
    offers: {
      ...SERVICE.offers,
      url:
        rootLangNormalizer(content) === 'it'
          ? toPublicURL(`/it/contatti`)
          : toPublicURL(`/en/contacts`),
    },
    url: toPublicURL(flattenToAppURL(content['@id'])),
    description: content.seo_description || content.description || '',
    name: content.seo_title ? content.seo_title : content.title,
  };

  if (isDocument(parent) && parent?.jsonld_metadata !== 'Service') {
    return {
      ...baseSchema,
      serviceType: parent.title,
    };
  } else return baseSchema;
};

const generateMetadata = (content) => {
  let metatype = content.jsonld_metadata ?? 'WebPage';
  if (isNewsItem(content)) {
    metatype = 'NewsArticle';
  } else if (isLRF(content)) {
    metatype = 'Organization';
  }

  switch (metatype) {
    case 'Organization':
      return orgSchema(content);
    case 'NewsArticle':
      return naSchema(content);
    case 'Service':
      return serviceSchema(content);
    default:
      return wpSchema(content);
  }
};

const generateBreadcrumbs = (breadcrumbs) => {
  // Fixed porting of https://github.com/RedTurtle/design-comuni-plone-theme/blob/829adcde0e42bb4e7e1e613f08821e5498db4e1f/src/components/ItaliaTheme/Breadcrumbs/GoogleBreadcrumbs.jsx
  const itemListElement = breadcrumbs.map((item, i) => {
    let ile = {
      '@type': 'ListItem',
      position: i + 1,
      name: item.title,
    };
    if (i < breadcrumbs.length - 1) {
      ile = { ...ile, item: toPublicURL(flattenToAppURL(item.url)) };
    }
    return ile;
  });
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};

const getStructuredData = (content, breadcrumbs) => {
  const ldJSON = generateMetadata(content);
  let structuredData = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(ldJSON),
    },
  ];
  if (!isLRF(content)) {
    const breadcrumbsldJSON = generateBreadcrumbs(breadcrumbs);
    structuredData = [
      ...structuredData,
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(breadcrumbsldJSON),
      },
    ];
  }

  return structuredData;
};

export { getStructuredData };
